import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import usersSlice from './slices/usersSlice';
import questionsSlice from './slices/questionsSlice';
import authSlice from './slices/authSlice';
import paramSlice from './slices/paramSlice';

const reducers = combineReducers({
  users: usersSlice,
  questions: questionsSlice,
  auth: authSlice,
  param: paramSlice,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export let persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

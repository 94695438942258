import { useState, useEffect } from 'react';
import GoQuestionCard from './GoQuestionCard';
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import QuesServices from '../../services/quesServices';
import { Question } from '../../type'

const GoQuestionList: React.FC<{ onSelectedChange: (selectedQuestions: string[]) => void, search: string | null }> = ({ onSelectedChange }) => {
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const dispatch = useDispatch<AppDispatch>();
  const { all, searchResults, filterResults } = useSelector((state: RootState) => state.questions);

  // useEffect(() => {
  //   dispatch(QuesServices.fetchQuestions());
  // }, [dispatch]);

  useEffect(() => {
    onSelectedChange(selectedQuestions);
    //console.log(selectedQuestions);
  }, [selectedQuestions, onSelectedChange]);

  const questionsToRender = searchResults ?? filterResults ?? all;

  return (
    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6
    h-auto w-auto">
      {questionsToRender && questionsToRender.map((question: Question) => (
        <GoQuestionCard
          key={question.id}
          type={question.type}
          subtype={question.subtype}
          imageSrc={question.imageUrl}
          difficulty={question.difficulty}
          onSelectedChange={(isSelected: boolean) => {
            if (isSelected) {
              setSelectedQuestions((prev) => [...prev, question.id]);
            } else {
              setSelectedQuestions((prev) => prev.filter((id) => id !== question.id));
            }
          }}
        />
      ))}
    </div>
  );
};

export default GoQuestionList;

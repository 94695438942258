import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from "../../store"
import QuesServices from "../../services/quesServices"
import { BiSearch } from 'react-icons/bi'
import useDebounce from '../../hook/useDebounce';

const Search = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      dispatch(QuesServices.fetchSearchingQuestions(debouncedSearchTerm));
    } else {
      dispatch(QuesServices.fetchQuestions());
    }
  }, [debouncedSearchTerm, dispatch]);


  // const handleSearch = (event: React.KeyboardEvent) => {
  //   if (event.key === 'Enter') {
  //     dispatch(QuesServices.fetchSearchingQuestions(searchTerm));
  //   }
  // }

  return (
    <div className="border-[1px] w-full md:w-auto py-2
    rounded-full shadow-sm hover:shadow-md transition cursor-pointer">
        <div className="flex flex-row items-center justify-between">
            <div className="text-sm pl-6 pr-2 text-gray-600
            flex flex-row items-center gap-3">
                <div className="p-2 bg-rose-500 rounded-full text-white">
                    <BiSearch size={18}/>
                </div>
                <input placeholder="Search" className="w-[12vh] focus:w-[20vh] outline-none transition-all" 
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                      //onKeyDown={handleSearch} 
                      />
            </div>
        </div>
    </div>
  )
}

export default Search

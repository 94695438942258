import { useState } from 'react';

interface GoQuestionCardProps {
  type: string;
  subtype: string;
  imageSrc: string;
  difficulty: string;
  onSelectedChange: (isSelected: boolean) => void;
};

const GoQuestionCard: React.FC<GoQuestionCardProps> = ({ type, subtype, imageSrc, difficulty, onSelectedChange }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked((prev) => !prev);
    onSelectedChange(!isChecked);
  };



  return (
    <div
      className="bg-white rounded-md shadow-md p-4 flex flex-col relative cursor-pointer"
      onClick={handleCheckboxChange}
    >
      <input
        type="checkbox"
        className="absolute top-0 right-0 mt-2 mr-2 cursor-pointer"
        checked={isChecked}
        readOnly
      />
      <img src={imageSrc} alt={type} className="w-full h-full rounded-md object-cover mb-4" />
      <h3 className="text-xl font-bold mb-2">{type}</h3>
      <p className="text-sm text-gray-600">{subtype}</p>
      <p className="text-sm text-gray-500">{difficulty}</p>
    </div>
  );
};

export default GoQuestionCard;

import api from "../api/Api"
import { createAsyncThunk } from "@reduxjs/toolkit";

const API_URL = process.env.REACT_APP_PARAMS ?? "" ;

const fetchParam = createAsyncThunk("param/fetchParam", async (paramId: number) => {
  const response = await api.get(API_URL + `/${paramId}`);
  return response.data;
});

// const fetchParam = async (paramId: number) => {
//   try {
//     const Response = await api.get(API_URL + `/${paramId}`);
//     return Response.data;

//   } catch (error) {
//     console.error('Error Param data:', error);
//   }
// };



const ParameterServices = {
  fetchParam,
}

export default ParameterServices;
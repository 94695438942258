import { useState, useEffect } from 'react'
import type { RouteObject } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import TeacherDashBoard from "./page/teacherDashboard/TeacherDashBoard";
import StudentDashboard from "./page/studentDashboard/StudentDashboard";
import NotFound from "./page/notFound/NotFound";
import { Constants } from "./constants";
import Login from "./page/login/Login";
import Navbar from "./components/navbar/Navbar";
import { useSelector, useDispatch } from "react-redux";
import jsCookie from 'js-cookie'
import AuthService from "./services/authServices";
import Profile from './page/profile/Profile';


interface UserState {
  auth: {
    user: {
      roles: string[]
    } | null,
    isLoggedIn: boolean
  };
};

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(useSelector((state: UserState) => state.auth.isLoggedIn));
  const roles = useSelector((state: UserState) => state.auth.user?.roles) || [""];
  //const isLoggedIn = useSelector((state: UserState) => state.auth.isLoggedIn)
  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  //const [role, setRole] = useState('');
  console.log(isLoggedIn);
  //console.log(role);
  
  // useEffect(() => {
  //   const token = jsCookie.get('GOL');
  //   console.log(token);
    
  //   if (token) {
  //     setIsLoggedIn(true);
  //   }
  // }, [isLoggedIn]);

    useEffect(() => {
      if (!isLoggedIn) {
        navigate('/');
      }
    }, [isLoggedIn, navigate]);

  useEffect(() => {
    const token = jsCookie.get('GOL');
    if (token) {
      dispatch(AuthService.setUserLoggedIn());
      setIsLoggedIn(true);
      console.log(isLoggedIn);
      console.log(token);
      
    }
  }, []);

  if (roles.includes(Constants.ROLE_TEACHER) && isLoggedIn) {
    return <><Navbar /><TeacherDashBoard /></>;
  }
  if (roles.includes(Constants.ROLE_STUDENT) && isLoggedIn) {
    return <><Navbar /><StudentDashboard /></>;
  }



  return <Login />
  //return <Test />
};

const routes: RouteObject[] = [
  {
    path: "/",
    element: <Login />, 
    children: [],
  },
  {
    path: "/dashboard",
    element: <Dashboard />, 
    children: [],
  },
  // {
  //   path: "/profile",
  //   element: <Profile />, 
  //   children: [],
  // },
  {
    path: "*",
    element: <NotFound />,
    children: [],
  },
];

export default routes;


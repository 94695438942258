import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../type";
import UserServices from "../services/userServices";

const usersSlice = createSlice({
  name: "users",
  initialState: {} as User,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(UserServices.fetchUsers.fulfilled, (state, action: PayloadAction<User>) => {
      return action.payload;
    });
  },
});

export default usersSlice.reducer;

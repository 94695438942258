import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import QuesServices from "../services/quesServices";
import { Question } from "../type";

type QuestionsState = {
  all: Question[],
  searchResults: Question[] | null,
  filterResults: Question[] | null,
}

const initialState: QuestionsState = {
  all: [] as Question[],
  searchResults: null,
  filterResults: null
};

const questionsSlice = createSlice({
  name: "questions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(QuesServices.fetchQuestions.fulfilled, (state, action: PayloadAction<Question[]>) => {
      state.all = action.payload;
      state.searchResults = null;  // clear search results when new data is fetched
    })
    .addCase(QuesServices.fetchSearchingQuestions.fulfilled, (state, action: PayloadAction<Question[]>) => {
      state.searchResults = action.payload;  // update search results
    })
    .addCase(QuesServices.filterQuestions.fulfilled, (state, action: PayloadAction<Question[]>) => {
      state.filterResults = action.payload;  
    })
  },
});

export default questionsSlice.reducer;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import AuthService from '../services/authServices';

interface User {
  id: string,
  username: string,
  roles: string[],
}

interface AuthState {
  status: 'idle' | 'loading' | 'succeeded' | 'failed',
  user: User | null,
  error: string | null,
  isLoggedIn: boolean
}

const initialState: AuthState = {
  status: 'idle',
  user: null,
  error: null,
  isLoggedIn: false,
};


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(AuthService.login.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(AuthService.login.fulfilled, (state, action: PayloadAction<User>) => {
        state.status = 'succeeded';
        state.user = action.payload;
        state.isLoggedIn = true;
        
      })
      .addCase(AuthService.login.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? null;
      })
      .addCase(AuthService.logout.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(AuthService.logout.fulfilled, (state) => {
        state.status = 'succeeded';
        state.user = null;
        state.isLoggedIn = false;
      })
      .addCase(AuthService.logout.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? null;
      })
      .addCase(AuthService.register.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(AuthService.register.fulfilled, (state, action: PayloadAction<User>) => {
        state.status = 'succeeded';
        state.user = action.payload;
      })
      .addCase(AuthService.register.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message ?? null;
      })
      .addCase(AuthService.setUserLoggedIn, (state) => {
        state.isLoggedIn = true;
      });
  },
});

export default authSlice.reducer;

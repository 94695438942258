import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { Param } from "../type";
import ParameterServices from "../services/parameterServices";

const paramSlice = createSlice({
  name: "param",
  initialState: {} as Param,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ParameterServices.fetchParam.fulfilled, (state, action: PayloadAction<Param>) => {
      return action.payload;
    });
  },
});

export default paramSlice.reducer;

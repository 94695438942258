import { AiOutlineMenu } from 'react-icons/ai';
import Avatar from '../Avatar';
import Menuitem from './Menuitem';
import { useCallback, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import AuthService from '../../services/authServices';
import Upload from './Upload';
import { Progress } from "@material-tailwind/react";
import { useNavigate } from 'react-router-dom';


const UserMenu: React.FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const userMenuRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const users = useSelector((state: RootState) => state.users);
  const [uploadProgress, setUploadProgress] = useState(0);


  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const toggleOpen = useCallback(() => {
    setIsOpen((value) => !value);
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const handleClickLogout = () => {
    dispatch(AuthService.logout());
    console.log("Logout");
  }

  

  
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative" ref={userMenuRef}>
      <div
        className="flex flex-row items-center gap-3"
      >
        <div
          onClick={handleModalShow}
          className="text-sm font-semibold py-3 px-4 rounded-full hover:bg-neutral-100 
            transition cursor-pointer text-gray-500"
        >
          Upload
        </div>
        <div
          onClick={toggleOpen}
          className="p-4 md:py-1 md:px-2 border-[1px]
            border-neutral-200 flex flex-row items-center gap-3
            rounded-full cursor-pointer hover:shadow-md transition"
        >
          <AiOutlineMenu />
          <div className="hidden md:block">
            <Avatar imgURL="/images/avatar.jpg" styling="rounded-full"/>
            
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="absolute rounded-xl shadow-md w-[40vw] md:w-[25vw]
            bg-white overflow-hidden right-0 top-12 text-sm">
          <div className="flex flex-col cursor-pointer">
            {/* <Menuitem onClick={() => {
              navigate("/profile")
            }} label="Profile" /> */}
            <Menuitem onClick={() => {}} label="Add Student" />
            <Menuitem onClick={handleClickLogout} label="Logout" />
          </div>
        </div>
      )}

      {showModal && (
        <Upload onClose={handleModalClose}/>
      )}
    </div>
  )
};


export default UserMenu;

interface AvatarProps {
  imgURL : string;
  styling: string;
}

const Avatar:React.FC<AvatarProps> = ({imgURL, styling}) => {
  return (
    <img 
    className= {styling}
    height="30"
    width="30"
    alt="Avatar"
    src= {imgURL}
    />
  )
}

export default Avatar
import React, { useState, useEffect } from 'react';
import { Assignment as AssignmentType } from '../../type';
import api from "../../api/Api";
import assignmentServices from '../../services/assignmentServices';

interface AssignmentProps {
  assignments: AssignmentType[];
}

const AssignmentsTable: React.FC<AssignmentProps> = ({ assignments }) => {
  //const [assignments, setAssignments] = useState([]);

  // useEffect(() => {
  //   api.get(`${process.env.REACT_APP_ASSIGNMENTS}/${studentId}`)
  //     .then(response => setAssignments(response.data))
  //     .catch(error => console.error('There was an error!', error));
  // }, [studentId]);
  const formatDate = (dateString: Date) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      return 'N/A';
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };

  const handleClick = (assignmentId: string) => {
    assignmentServices.genAssignmentPDF(assignmentId)
      .then(fileURL => {
        window.open(fileURL);
      })
      .catch(error => console.error(error));
  }

  return (
    <div className='flex flex-col '>
      <div className='flex justify-center'>
        <p className="text-3xl py-4 ">Assignments</p>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Due Date</th>
              <th>Is Completed</th>
              <th>Complete Date</th>
            </tr>
          </thead>
          <tbody>
            {assignments.map(assignment => (
              <tr key={assignment.id}>
                <td>{assignment.id}</td>
                <td>{formatDate(assignment.dueDate)}</td>
                <td>{assignment.isCompleted ? 'Yes' : 'No'}</td>
                <td>{formatDate(assignment.dateCompleted) || 'N/A'}</td>
                <td><button onClick={() => {
                  handleClick(assignment.id)
                }}>Download</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
    </div>
    
  );
};

export default AssignmentsTable;

import React, {useEffect, useState} from 'react'
import DownloadAssignment from '../../components/downloadAssignment/DownloadAssignment'
import Assignment from '../../components/assignmentSection/Assigment'
import AssignmentList from '../../components/assignmentSection/AssignmentList'
import AssignmentsTable from '../../components/assignmentSection/AssignmentsTable'
import api from "../../api/Api"
import AssignmentServices from '../../services/assignmentServices'
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../store";

const StudentDashboard = () => {
  const studentId = useSelector((state: RootState) => state.auth.user!.id);
  const [assignments, setAssignments] = useState([]);
  const [generatedPDFs, setGeneratedPDFs] = useState([]);



  useEffect(() => {
    AssignmentServices.fetchStudentAssignment(studentId)
    .then(data => setAssignments(data))
    .catch(error => console.error(error));
    
  }, [studentId]);
  

  return (
    <div className='flex flex-col h-screen bg-white'>
      <div className='flex flex-1 h-1/2 shadow-md justify-center'>
        <DownloadAssignment assignments={generatedPDFs} />
      </div>
      <div className="flex flex-1 h-1/2 shadow-md justify-center">
        <AssignmentsTable assignments={assignments} />
      </div>

    </div>
  )
}

export default StudentDashboard
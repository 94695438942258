import axios from 'axios';
import Cookies from "js-cookie";

const getCookie = (name: string): string => {
  return Cookies.get(name) ?? '';
}

const API_URL = process.env.REACT_APP_DOMAIN ?? "" ;

//const API_URL = "http://localhost:8080" ;

const instance = axios.create({
  baseURL: API_URL,
  withCredentials: true, 
});

instance.interceptors.request.use((config) => {
  const token = getCookie('GOL');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});


export default instance;

import React, { useState } from "react";
import Loading from "./components/Loading";
import { useRoutes } from "react-router-dom"
import routes from "./routes";

const App: React.FC = () =>{
  const element = useRoutes(routes);
  return element;
  // return (
  //   <div>
  //     <Providers>
  //       useRoutes(routes)
  //     </Providers>
  //     {/* <Loading /> */}

  //   </div>
  //);
};

export default App;

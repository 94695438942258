import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/Api"

const API_URL = process.env.REACT_APP_USERS ?? "" ;

const fetchUsers = createAsyncThunk("users/fetchUsers", async () => {
  const response = await api.get(API_URL);
  return response.data;
});

const fetchStudent = createAsyncThunk("teacher/fetchStudent", async (teacherId: string) => {
  const response = await api.get(API_URL + `/teacher/${teacherId}`);
  return response.data;
});


const UserServices = {
  fetchUsers,
  fetchStudent
}

export default UserServices;
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import UserServices  from "../../services/userServices";
import { User } from "../../type";
import { unwrapResult } from "@reduxjs/toolkit";
import Avatar from "../Avatar";

const Sidebar = () => {
  const dispatch = useDispatch<AppDispatch>();
  const teacher = useSelector((state: RootState) => state.auth.user!.id);
  const [students, setStudents] = useState<User[]>([]);
  
  useEffect(() => {
    dispatch(UserServices.fetchStudent(teacher))
      .then(unwrapResult)
      .then(response => {
        setStudents(response);
        console.log(students)
      })
      .catch(error => {
        console.error("Failed to fetch students:", error);
      });
  }, [teacher, dispatch]);

  useEffect(() => {
    console.log(students);
  }, [students]);
  

  return (
    <div className="h-screen min-w-min p-4 bg-white hidden md:block ">
      <ul>
        {students.map((student) => (
          (
            <li key={student.id} className="flex py-2 px-4 cursor-pointer hover:bg-neutral-100 rounded-xl">
              <Avatar imgURL={student.logoUrl} styling="rounded-full border-[1px] w-[30px] h-[30px]" />
              <span className="px-4">{student.firstName}</span>
            </li>
          )
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;

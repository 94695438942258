import React, { useState, useEffect } from 'react';
import api from '../../api/Api';
import { Providers } from '../../Providers';
import GoQuestionList from '../../components/goQuestionCard/GoQuestionList';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { BiFilter } from 'react-icons/bi';
import UserServices from '../../services/userServices';
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from '@reduxjs/toolkit';
import { RootState, AppDispatch } from "../../store";
import { User } from '../../type';
import Select, { OnChangeValue, ActionMeta} from "react-select";
import Filter from '../../components/filter/Filter';


type OptionType = {
  value: string;
  label: string;
};

const TeacherDashBoard = () => {
  const [goQuestionIdList, setGoQuestionIdList] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const dispatch = useDispatch<AppDispatch>();
  const teacher = useSelector((state: RootState) => state.auth.user!.id);
  const [students, setStudents] = useState<User[]>([]);
  const [selectedStudents, setSelectedStudents] = useState<OnChangeValue<OptionType, true>>([]);




  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  useEffect(() => {
    dispatch(UserServices.fetchStudent(teacher))
      .then(unwrapResult)
      .then(response => {
        setStudents(response);
      })
      .catch(error => {
        console.error("Failed to fetch students:", error);
      });
  }, [teacher, dispatch]);

  const handleModalSubmit = () => {
    if(goQuestionIdList.length > 6) {
      alert("You cannot create assignment with more than 6 questions.");
      return;
    }
    else if (goQuestionIdList.length = 0) {
      alert("You cannot create assignment with 0 question.");
      return;
    }
    console.log(title, students);
    handleSubmit();
    handleModalClose();
  }

  const handleSubmit = async () => {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_ASSIGNMENTS}/create-assignment`, 
        { 
          teacherId: teacher,
          studentId: selectedStudents.map((student) => student.value),
          goQuestionIdList,
          title
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      const data = await response.data;
      console.log(data);
    } catch (error) {
      console.error('Failed to submit questions', error);
    }
  };

  const handleGen = async () => {
    if(goQuestionIdList.length > 6) {
      alert("You cannot generate with more than 6 questions.");
      return;
    }
    
    try {
      const response = await api.post(
        `${process.env.REACT_APP_ASSIGNMENTS}/generate-assignment`, 
        {
          goQuestionIdList,
          title
        },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          responseType: 'blob'
        }
      );
  
      const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      window.open(fileURL, '_blank');
    } catch (error) {
      console.error('Failed to generate', error);
    }
  };
  
  
  

  const handleStudentChange = (selectedOptions: OnChangeValue<OptionType, true>, actionMeta: ActionMeta<OptionType>) => {
    setSelectedStudents(selectedOptions);
    console.log(`Options selected:`, selectedOptions);
  };

  const studentOptions = students.map(student => ({ value: student.id, label: student.firstName }));


  return (
    <>
      <div className="flex items-start gap-6">
        <Sidebar />
        <div className="flex-1">
          <div className="flex justify-between items-center p-4">
            <div>
              <Filter />
            </div>
            <div>
              <button onClick={handleGen} className="text-sm uppercase shadow-md bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-800 mr-4">
                generate
              </button>
              <button onClick={handleModalShow} className="text-sm uppercase shadow-md bg-indigo-600 text-white py-2 px-4 rounded hover:bg-indigo-800">
                assign
              </button>
            </div> 
          </div>
          <GoQuestionList onSelectedChange={setGoQuestionIdList}  search={null}/>
        </div>


      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity">
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="mt-3">
                  <label className="block">Student</label>
                  <Select
                    options={studentOptions}
                    onChange={handleStudentChange}
                    isMulti
                  />
                </div>
                <div className= "pt-4">
                  <label className="block">Title</label>
                  <input type="text" value={title} onChange={e => setTitle(e.target.value)} className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none" placeholder="Enter title"/>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button onClick={handleModalSubmit} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:ml-3 sm:w-auto sm:text-sm">
                  Submit
                </button>
                <button onClick={handleModalClose} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      </div>
    </>
  );
};

export default TeacherDashBoard;

import {useState, useCallback, useEffect} from 'react'
import Select from 'react-select';
import { useDropzone } from 'react-dropzone';
import { Param } from '../../type';
import ParameterServices from '../../services/parameterServices';
import QuesServices from '../../services/quesServices';
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../store";

interface UploadProps {
  onClose: () => void;
}

const Upload:React.FC<UploadProps> = ( {onClose} ) => {
  const [files, setFiles] = useState<File[]>([]);
  const [type, setType] = useState<Param>({ value: [] });
  const [subtype, setSubtype] = useState<Param>({ value: [] });
  const [difficulty, setDifficulty] = useState<Param>({ value: [] });
  const [category, setCategory] = useState("");
  const [selectedType, setSelectedType] = useState<Param>({ value: [] });
  const [selectedSubtype, setSelectedSubtype] = useState<Param>({ value: [] });
  const [selectedDifficulty, setSelectedDifficulty] = useState<Param>({ value: [] });
  const dispatch = useDispatch<AppDispatch>();
  const param = useSelector((state: RootState) => state.param.value)


  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles(acceptedFiles);
    console.log(acceptedFiles);
    
  }, []);


  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  const typeOptions = type.value?.map(value => ({ value: value, label: value }));
  const subTypeOptions = subtype.value?.map(value => ({ value: value, label: value }));
  const diffcultyOptions = difficulty.value?.map(value => ({ value: value, label: value }));

  // useEffect(() => {
  //   ParameterServices.fetchParam(1)
  //   .then(data => setDifficulty(data))
  //   .catch(error => console.error(error));

  //   ParameterServices.fetchParam(2)
  //   .then(data => setType(data))
  //   .catch(error => console.error(error));

  //   ParameterServices.fetchParam(3)
  //   .then(data => setSubtype(data))
  //   .catch(error => console.error(error));
  // }, [])

  useEffect(() => {
    dispatch(ParameterServices.fetchParam(1))
    .then(action  => setDifficulty(action.payload))
    dispatch(ParameterServices.fetchParam(2))
    .then(action => setType(action.payload))
    dispatch(ParameterServices.fetchParam(3))
    .then(action => setSubtype(action.payload))

  }, [dispatch])
  
  const handleModalSubmit = () => {
    const formData = new FormData();
    // Append the file
    files.forEach(file => formData.append('image', file));
  
    formData.append('type', selectedType.value[0]);
    formData.append('subtype', selectedSubtype.value[0]);
    formData.append('difficulty', selectedDifficulty.value[0]);
    formData.append('category', category);
    dispatch(QuesServices.uploadQuestion(formData));
    console.log(formData);
    //handleModalClose();
    onClose();
  }

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity pointer-events-none">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white z-50 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h1 className="text-lg">Upload File</h1>
            <div className="mt-3">
              <label className="block">Type</label>
              <Select 
                options={typeOptions}
                onChange={selectedOption => selectedOption && setSelectedType({ value: [selectedOption.label] })}
              />
            </div>
            <div className="mt-3">
              <label className="block">Subtype</label>
              <Select 
                options={subTypeOptions}
                onChange={selectedOption => selectedOption && setSelectedSubtype({ value: [selectedOption.label] })}
              />
            </div>
            <div className="mt-3">
              <label className="block">Difficulty</label>
              <Select 
                options={diffcultyOptions}
                onChange={selectedOption => selectedOption && setSelectedDifficulty({ value: [selectedOption.label] })}
              />
            </div>
            <div className="mt-3">
              <label className="block">Category</label>
              <input type="text" className="w-full px-3 py-2 border-gray-300 border rounded-lg focus:outline-none"
                onChange={e => setCategory(e.target.value)}
              />
            </div>
            <div className="p-5 relative border-4 border-dotted border-gray-300 rounded-lg mt-3" {...getRootProps()}>
              <svg className="text-blue-500 w-24 mx-auto mb-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap ="round" strokeLinejoin ="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" /></svg>
              <div className="input_field flex flex-col w-max mx-auto text-center" >
                <input className="text-sm cursor-pointer w-36 hidden" {...getInputProps()}/>
                <div className="bg-blue-600 text-white border border-gray-300 rounded font-semibold cursor-pointer p-1 px-3 hover:bg-blue-500">Select</div>
                <div className="text-blue-500 uppercase">or drop files here</div>
              </div>
            </div>

            <aside>
              <h4>Files</h4>
              <ul>{files.map(file => <li key={file.name}>{file.name}</li>)}</ul>
            </aside>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button onClick={handleModalSubmit} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-blue-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue transition ease-in-out duration-150 sm:ml-3 sm:w-auto sm:text-sm">
              Submit
            </button>
            <button onClick={onClose} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
      )}

export default Upload
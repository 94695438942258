import { AnyAction, AsyncThunkAction, Dispatch, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/Api"
import { AppDispatch } from "../store";

const API_URL = process.env.REACT_APP_GOQUESTIONS ?? "" ;

interface FilterParameters {
  [key: string]: string | undefined;
  type?: string;
  subtype?: string;
  difficulty?: string;
}


const fetchQuestions = createAsyncThunk("questions/fetchQuestions", async () => {
  const response = await api.get("/questions");
  return response.data;
})

const uploadQuestion = (formData: FormData) => async (dispatch: AppDispatch) => {
  try {
  await api.post(API_URL + '/batch-upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    console.log('File uploaded successfully');
    dispatch(fetchQuestions());
  }catch(error){
    console.error('Failed to upload file', error);
  };
}

const fetchSearchingQuestions = createAsyncThunk("questions/fetchSearchingQuestions",async (category: String) => {
  const response = await api.get(API_URL + `/search/${category}`)
  return response.data;
})

const filterQuestions = createAsyncThunk("questions/filterQuestions", async (params: FilterParameters) => {
  let query = Object.keys(params)
    .filter(key => params[key])  // 過濾掉空值
    .map(key => `${key}=${params[key]}`)
    .join('&');

  const response = await api.get(API_URL + '?' + query);
  return response.data;
})

// const filterQuestions = async (params: FilterParameters) => {
//   let query = Object.keys(params)
//     .filter(key => params[key])  // 過濾掉空值
//     .map(key => `${key}=${params[key]}`)
//     .join('&');

//   const response = await api.get(API_URL + '?' + query);
//   return response.data;
// }


const QuesServices = {
  fetchQuestions,
  uploadQuestion,
  fetchSearchingQuestions,
  filterQuestions,
}

export default QuesServices;

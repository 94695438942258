import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../store";
import { unwrapResult } from '@reduxjs/toolkit';
import AuthService from "../../services/authServices";
import { Constants } from '../../constants';

const Login:React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const auth = useSelector((state: RootState) => state.auth);

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const resultAction = await dispatch(AuthService.login({ userName, password }));
      const user = unwrapResult(resultAction);
      console.log("login")
      console.log({user})
      console.log(user.roles);

      navigate('/dashboard');
      
    } catch (err) {
      console.error('Failed to log in: ', err);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-200">
      <div className="p-10 bg-white rounded-lg shadow-xl w-[400px]">
        <h1 className="mb-6 text-3xl font-semibold text-center text-gray-600">Login</h1>
        <form onSubmit={handleSubmit} className="space-y-5">
          <div>
            <label htmlFor="username" className="block mb-1 text-sm text-gray-500">Username</label>
            <input
              type="text"
              id="username"
              placeholder="Username"
              value={userName}
              onChange={e => setUserName(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
          </div>
          <div>
            <label htmlFor="password" className="block mb-1 text-sm text-gray-500">Password</label>
            <input
              type="password"
              id="password"
              placeholder="Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
              className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:border-indigo-500"
            />
          </div>
          <button type="submit" className="w-full py-2 text-white bg-indigo-500 rounded-md hover:bg-indigo-600">Log in</button>
        </form>
      </div>
    </div>
  );
};

export default Login;

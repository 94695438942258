import React, { useState, useEffect, Fragment} from "react";
import { BiFilter } from "react-icons/bi";
import { Dialog, Disclosure, Menu, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon, FunnelIcon, MinusIcon, PlusIcon} from '@heroicons/react/20/solid'
import { Param } from "../../type";
import ParameterServices from '../../services/parameterServices';
import QuesServices from "../../services/quesServices";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../store";

function Filter() {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [type, setType] = useState<Param>({ value: [] });
  const [subtype, setSubtype] = useState<Param>({ value: [] });
  const [difficulty, setDifficulty] = useState<Param>({ value: [] });
  const dispatch = useDispatch<AppDispatch>();
  const [filters, setFilters] = useState({
    type: "",
    subtype: "",
    difficulty: "",
  });

  const filterParam = [
    {
      id: 'type',
      name: 'Type',
      options: type.value,
    },
    {
      id: 'subtype',
      name: 'Subtype',
      options: subtype.value,
    },
    {
      id: 'difficulty',
      name: 'Difficulty',
      options: difficulty.value,
    },
  ]

  useEffect(() => {
    dispatch(ParameterServices.fetchParam(1))
    .then(action  => setDifficulty(action.payload))
    dispatch(ParameterServices.fetchParam(2))
    .then(action => setType(action.payload))
    dispatch(ParameterServices.fetchParam(3))
    .then(action => setSubtype(action.payload))

  }, [dispatch])

  useEffect(() => {
    dispatch(QuesServices.filterQuestions(filters))
  }, [filters, dispatch]);
  

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
  
    if (checked) {
      setFilters(prevState => ({
        ...prevState,
        [name]: value
      }));
      //console.log(value)
    } else {
      setFilters(prevState => ({
        ...prevState,
        [name]: ""
      }));
    }
  };

  return (
    <div className="flex flex-row">
      <BiFilter size={20} className="cursor-pointer md:hidden " onClick={() => setMobileFiltersOpen(true)} />
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setMobileFiltersOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4 border-t border-gray-200">
                    {filterParam.map((section) => (
                      <Disclosure as="div" key={section.id} className="border-t border-gray-200 px-4 py-6">
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                                <span className="font-medium text-gray-900">{section.name}</span>
                                <span className="ml-6 flex items-center">
                                  {open ? (
                                    <MinusIcon className="h-5 w-5" aria-hidden="true" />
                                  ) : (
                                    <PlusIcon className="h-5 w-5" aria-hidden="true" />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                  {section.options.map((option, optionIdx) => (
                                      <div key={optionIdx} className="flex items-center">
                                          <input
                                              id={`filter-mobile-${section.id}-${optionIdx}`}
                                              name={`${section.id}`}
                                              defaultValue={option}
                                              type="checkbox"
                                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                              onChange={handleChange}
                                          />
                                          <label
                                              htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                              className="ml-3 min-w-0 flex-1 text-gray-500"
                                          >
                                              {option}
                                          </label>
                                      </div>
                                  ))}
                              </div>
                          </Disclosure.Panel>

                          </>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
      </Transition.Root>
      <div className="hidden md:block z-[5]">
        {filterParam.map((section) => (
          <div className="inline-block ml-4" key={section.id}>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex justify-center rounded-full bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                  {section.name}
                  <ChevronDownIcon
                    className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1">
                    <form className="space-y-6 max-h-[calc(1.5rem*15)] overflow-y-auto">
                      {section.options.map((option, optionIdx) => (
                        <div key={optionIdx} className="flex items-center">
                          <input
                            id={`filter-desktop-${section.id}-${optionIdx}`}
                            name={`${section.id}`}
                            defaultValue={option}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            onChange={handleChange}
                          />
                          <label
                            htmlFor={`filter-desktop-${section.id}-${optionIdx}`}
                            className="ml-3 min-w-0 flex-1 text-gray-500"
                          >
                            {option}
                          </label>
                        </div>
                      ))}
                    </form>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        ))}
      </div>

    </div>
  );
}

export default Filter;

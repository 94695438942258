import api from "../api/Api"

const API_URL = process.env.REACT_APP_ASSIGNMENTS ?? "" ;



const fetchStudentAssignment = async (studentId: string) => {
  try {
    const assignmentsResponse = await api.get(API_URL + `/${studentId}`);
    return assignmentsResponse.data;

  } catch (error) {
    console.error('Error fetching student data:', error);
  }
};

const genAssignmentPDF = async (assignmentId: string) => {
  try {
    const pdfResponse = await api.get(API_URL + `/download/${assignmentId}`, { responseType: 'blob' });
    const file = new Blob([pdfResponse.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    return fileURL;
  } catch (error) {
    console.error('Error generate PDF:', error);
  }
};


const AssignmentServices = {
  fetchStudentAssignment,
  genAssignmentPDF
}

export default AssignmentServices;
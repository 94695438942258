import axios, { AxiosResponse } from "axios";
import { createAsyncThunk, createAction  } from "@reduxjs/toolkit";
import api from "../api/Api"

const API_URL = process.env.REACT_APP_AUTH ?? "" ;

interface UserCredentials {
  userName: string,
  password: string,
}

interface NewUser extends UserCredentials {
  userName: string,
  password: string,
  firstName: string,
  lastName: string,
  email: string,
  
}

interface UserResponse {
  id: string,
  username: string,
  roles: string[],
}

const login = createAsyncThunk<UserResponse, UserCredentials>('auth/login', async (userCredentials) => {
  const response: AxiosResponse<UserResponse> = await api.post(API_URL + '/login', userCredentials);
  return response.data;
});

const logout = createAsyncThunk('auth/logout', async () => {
  const response: AxiosResponse<void> = await api.post(API_URL + '/logout');
  return response.data;
});

const register = createAsyncThunk<UserResponse, NewUser>('auth/register', async (newUser) => {
  const response: AxiosResponse<UserResponse> = await api.post(API_URL + '/signup', newUser);
  return response.data;
});

const setUserLoggedIn = createAction('auth/setUserLoggedIn');



const AuthService = {
  register,
  login,
  logout,
  setUserLoggedIn,
}

export default AuthService;

import React from 'react';

interface DownloadAssignmentProps {
  assignments: any[];
}

const DownloadAssignment: React.FC<DownloadAssignmentProps> = ({ assignments }) => {
  return (
    <div>
      <p className="text-3xl py-4">Download Assignments</p>
      <ul>
        {assignments.map((assignment) => (
          <li key={assignment._id}>
            {/* Assignment - <a download href={assignment.file_url}>Download</a> */}
            Assignment - <a href="assignment.file_url" className="uppercase">download</a>

          </li>
        ))}
      </ul>
    </div>
  );
};

export default DownloadAssignment;

import { useNavigate } from "react-router-dom";

const Logo = () => {
    const navigate = useNavigate();
    return (
        <img 
            alt="Logo"
            className="hidden md:block cursor-pointer w-[90px] h-[90px]"
            height="100"
            width="100"
            src="/images/GOL-Logo.jpg"
            onClick={() => {
                navigate("/dashboard")
            }}
        />
    );
};

export default Logo;
